.login-form-container {
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 700px;
    margin: auto;

}

.login-form-container .form {

    width: 90%;
    margin: auto;

}

.add-invoice-link {
    float: right;

}

.page-btn {
    font-size: 15px;
    color: #f1f1f1;
    padding: 10px 17px;
    margin: 4px;
    background: #ff2404 !important;
    border: none;
}


.login-form-container input {
    padding: 27px;
    border-radius: 4px;

}


.login-form-container button {
    width: 100%;
    border-radius: 4px;
    padding: 14px;
    background-color: #191f64;
    margin-top: 30px;
}

.login-form-container .bottom-sect {
    display: flex;
    justify-content: space-between;
    padding: 0px 0px;

    padding: 0px 20px;
    margin: 30px 0px;
    color: gray;
}


.employee-page-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    margin: 30px auto;
    font-family: var(--roboto-font);
}

.employee-page-header {
    font-family: var(--roboto-font);
    color: #333;
    font-weight: normal;
}

.sub-deadline {
    color: grey;
    font-size: 11px;

}

.project-NearlyCompleted {
    color: yellowgreen;

}


.project-QA {
    color: rgb(55, 50, 205);

}


.project-EarlyStages {
    color: rgb(205, 102, 50);

}

.project-Complete {
    color: rgba(60, 227, 22, 0.981);

}



.employee-tbl thead {
    background-color: #394d70;
    color: #f3f3f3;

}

.page-title {
    width: 90%;
    margin: auto;
    padding: 40px 0px;
    color: rgba(51, 51, 51, 0.666);
    font-weight: 400;
}


.create-invoice-form-sect {
    margin: 30px;
    border: 1px solid #e8e8e8
}


.create-invoice-form-sect header {
    padding: 10px 12px;
    display: block;
    font-size: 16px;
    border: 1px solid #e8e8e8;
    background-color: #f9f9f9;
    margin-top: 20px;
}

.submit-btn {
    background: var(--theme1);
    border: none;

}


.create-invoice-form input {
    width: 86%;
    margin: 20px;
    display: inline-block;
    border: 2px solid #dbdbdb;
    border-radius: 2px;
    padding: 8px 20px;
    height: auto;
    margin-right: auto;
}

.create-invoice-form .css-b62m3t-container {
    position: relative;
    box-sizing: border-box;
    width: 87%;
    margin: 20px auto;
}


.create-invoice-form .row {

    align-items: center;
}

.create-invoice-form label {
    font-weight: bold;
    margin: 15px 30px;
    font-family: var(--roboto);
    color: #565656;
}
.login-img-col{
    padding: 0;
}



@media screen and (max-width:1200px) {

    .login-img-col {
        display: none;
    }

    .form-col {
        min-width: 100vw;
    }

}


@media screen and (max-width:767px) {
    .login-img-col {
        display: none;
    }

    .add-invoice-link {
        float: none;
        margin-top: 20px;
        display: block;
    }
    .create-invoice-form-sect {
        margin: 0px;
        border: 1px solid #e8e8e8;
    }
}

@media screen and (max-width:500px) {
    .page-title {
        font-size: 22px;
    }

    .route-data {
        font-size: 12px;
    }



    .bottom-sect {
        font-size: 13px;
        flex-wrap: wrap;

    }

    .bottom-sect div {
        margin: 13px 0px;
    }


    .login-form-container input {
        padding: 21px;
        border-radius: 6px;
    }

    .login-form-container .logo {
        width: 120px;
        height: 80px;
        scale: 1.8;
        margin-left: 43px;
    }
    /* .create-invoice-form-sect {
        margin: 0px;
        border: 1px solid #e8e8e8;
    } */
}

.create-partner-form-main {
    /* background: #f0f0f0; */
    padding-top: 30px;
    padding: 30px;
    margin: auto;
    /* margin-top: 30px; */
    /* width: 90%; */
    width: 100%;
    /* background-color: #fff; */
    /* box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px; */
    /* max-width: 1300px; */
    /* box-shadow: 0px 3px 10px -2px rgba(0, 0, 0, 0.2); */
    font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
    min-height: calc(100vh - 74px);
}

.dashboard-page-main{
    padding-top: 0px;
    padding: 0px;
}

.dashboard-page-main .dashboard-cont{
    /* padding-top: 0; */
    padding: 30px;
}

.create-partner-form {
    background: #fff;
    padding: 40px;
    border-radius: 4px;
    /* box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px; */

}
.css-1nmdiq5-menu{
    top:100%;position:fixed;width:100%;z-index:900 !important;background-color:hsl(0, 0%, 100%);border-radius:4px;box-shadow:0 0 0 1px hsla(0, 0%, 0%, 0.1),0 4px 11px hsla(0, 0%, 0%, 0.1);margin-bottom:8px;margin-top:8px;box-sizing:border-box;
  }

.form-heading-label .client-lbl{
    margin-left: 0px;
    color: #666;
    display: block;
    margin-top: 20px;
    font-size: 17px;

}
.form-heading-label .client-lbl .client-lbl-value{
  /* border-bottom: 1px solid #333; */
}

.form-heading-label .client-lbl i{
  margin-right: 0.8rem;
}
.form-heading-label {
    /* background: #ff1f01; */
    /* color:#fff; */
    color: #ff1f01;

    font-weight: bold;
    padding: 12px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1PX SOLID #ededde;

}

.form-heading-label h1 {
    font-size: 1.4rem;
    font-weight: normal;
    padding-bottom: 8PX;
    margin-top: 8px;
    width: 60%;

}

.create-partner-form-main .form-body {

    padding: 20px;
    background-color: #fff;
    /* box-shadow: 0px 1px 1px 1px rgb(0 0 0 / 15%); */
    /* box-shadow:0px 3px 10px -2px rgba(0, 0, 0, 0.2); */


}

.form-body .inp-rows {
    display: grid;
    grid-template-columns: 48% 48%;
    gap: 2.4rem;
    margin: 14px 0px;

}

.form-body .inp-rows label {

    margin: 10px 0px;


}

.form-err-label {
    content: '* Required';
    color: crimson;
    margin-left: 12px;
    font-size: 14px;

}

.cols-3 {
    grid-template-columns: 48% 23% 23% !important;
    gap: 2rem !important;


}

.cols-33 {
    grid-template-columns: 32% 32% 32% !important;
    gap: 1.4rem !important;


}

.form-drpdwn {
    width: 100%;
    margin-left: 20px;
}

.form-drpdwn .css-13cymwt-control {
    border: none;

}

.form-drpdwn .css-qbdosj-Input,
.form-drpdwn .css-1fdsijx-ValueContainer {
    margin-top: 0px;
    margin-bottom: 0px;
    padding-top: 0px;
    padding-bottom: 0px;
}

.form-body input {
    /* border:none;
    padding:20px;
    border-left: 2px solid rgba(128, 128, 128, 0.379);
    border-radius: 0px;
    margin: 2px 20px; */
    border: none;
    padding: 14px;
    border-left: 2px solid rgba(128, 128, 128, 0.379);
    border-radius: 0px;
    margin: 2px 20px;
    height: calc(1.5em + .45rem + 2px);
    margin-right: 1px;

}

.cp-name,
.cp-row {
    display: flex;
    align-items: center;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    border: none;
    color: gray;
    padding-left: 10px;
}

.cp-phone {
    display: flex;
    align-items: center;
    padding-left: 10px;

    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    border: none;
    color: gray;
}

.cp-email {
    display: flex;
    align-items: center;
    padding-left: 10px;

    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    border: none;
    color: gray;
}

.cp-contact {
    display: flex;
    align-items: center;
    padding-left: 10px;

    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    border: none;
    color: gray;
}

.cp-id {
    display: flex;
    align-items: center;
    padding-left: 10px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    border: none;
    color: gray;
}


.form-bottom {
    width: 100%;
    margin: 10px auto;
    padding-bottom: 30PX;
    padding-left: 20PX;
}


@media screen and (max-width:1100px) {
    .form-heading-label .client-lbl{
       display: block;
       font-size: 16px;
       margin-top: 20px;
    }
}


@media screen and (max-width:690px) {
    .form-body .inp-rows {
        display: grid;
        grid-template-columns: 100%;
        gap: 2.4rem;
        margin: 14px 0px;

    }

    .create-partner-form,
    .create-partner-form-main {
        padding: 4px
    }

    .create-partner-form .form-heading-label h1 {
        font-size: 17px;
        /* font-size: 1.4rem; */
        font-weight: normal;
        padding-bottom: 8PX;
        margin-top: 8px;
        width: 82%;
    }
    .form-heading-label .client-lbl{
       font-size: 14px;
       margin-top: 8px;
    }

    .form-heading-label {
        flex-wrap: wrap;
    }

}

.form-err-text {
    color: rgb(172, 28, 28);
    padding: 5px;
    margin-top: 0PX;
    DISPLAY: BLOCK;

}

.form-err-text::before {
    content: '*';
    margin: 20px 4px;

}

.css-1nmdiq5-menu {
    min-width: 140px;
    z-index: 10000 !important;
    position: absolute;
}

.custom-link1 {
    text-decoration: none;
    color: #fff;
}

.tbl-action-col {
    display: flex;
}

.forgot-password-page-parent {
    min-height: 100vh;
    max-width: 100vw;
    margin: 0;
    border-radius: 10px;
    background-color: #f0f0f0;
}

/* .form-body-row{
    display: grid;
    grid-template-columns: 30% 60%;
}

.form-body-row .img-col{
  background: var(--theme1);
  border-top-left-radius:10px;
  border-bottom-left-radius:10px ; 
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap:10px;
  color: #f1f1f1;


}

.form-body-row .img-col i{

font-size: 52px;


} */


.forgot-password-form-container {
    display: flex;
    justify-content: start;
    align-items: start;
    max-width: 700px;
    margin: auto;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    /* max-height: 70vh; */
    width: 70%;
    /* height:70vh; */
    border-radius: 10px;
    background-color: #fff;
    flex-direction: column;

}

.login-form-main-parent {
    min-height: 100vh;
    max-width: 100vw;
    margin: 0;
}

.login-img-col .inner-sect {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;

}

.login-img-col .inner-sect img {
    /* object-fit: contain;
    height: 100vh;
    -webkit-mask-image: linear-gradient(rgba(30, 29, 29, 0.733), rgb(0 0 0 / 92%)); */
    object-fit: cover;
    min-height: 100vh;
    -webkit-mask-image: linear-gradient(rgba(30, 29, 29, 0.733), rgb(0 0 0 / 92%));
    width: 100%;
}

.login-text {
    font-size: 16px;
    color: gray;
    font-weight: NORMAL;
    margin-top: 16px;
}

.login-form-main-parent .form-col {
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

.forgot-password-form-container .logo-heading {
    font-weight: bold;
    font-size: 36px;
    text-align: center;
    margin-top: -10%;
}

.forgot-password-form-container .label-heading {
    width: 100% !important;
    text-align: center;
    font-weight: 500;
}

.remember-me-txt {
    color: #333;
    font-weight: 600;
}

.tbl-action-btn-dwn {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 87px;
    cursor: pointer;

}


/* .bl-1{
    border-left: 4px solid rgb(209, 43, 43);
    color:rgb(209, 43, 43) ;
}

.bl-2{
    border-left: 4px solid rgb(75, 202, 11);
    color: rgb(75, 202, 11);
}

.bl-3{
    border-left: 4px solid rgb(115, 43, 209);
    color: rgb(115, 43, 209);
}

.bl-4{
    border-left: 4px solid rgb(43, 123, 209);
    color: rgb(43, 123, 209);
} */

.bl-1,
.bl-2,
.bl-3,
.bl-4 {
    /* font-family: fantasy; */
}

.card-overview h3,.card-overview-sub h3 {
    /* font-family: system-ui; */
    font-weight: 400;
    padding: 20px;
    font-size: 20px;
    color: rgb(167 152 152);
}




.card-overview::after,.card-overview2::after {
    border: 1px solid rgb(238 238 238);
    margin: 13px 0px;
    position: absolute;
    margin: 30px 0px;
    width: 70%;
    height: 1px;
    background-color: rgb(238 238 238);
    bottom: 0px;
    left: calc(15%);
    content: '';
}

.card-overview::after{
    height: 0px;
    border: 0;
}

.card-overview .detail,.card-overview-sub .detail {
    /* font-family: serif; */
    font-weight: 400;
    padding: 0px 20px;
    color: #666;
    font-size: 28px;
    margin: 0 !important;
}



.dashboard-filter-sect {
    display: flex;
    padding: 10px 20px;
    border-radius: 10px;
    /* box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px; */
    border: 1px solid #f1f1f1;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
    flex-wrap: wrap;
    height: 64px;
  
}

.dashboard-filter-sect .filtr{
    display: flex;
    align-items: center;
    width: 300px;
}
.dashboard-filter-sect .filtr span{
    margin: 0px 20px;
    margin-left: 0;
    font-size: 14px;
    color:#736f6f
}

.sub-per-lbl{
    font-size: 18px;
    font-family: system-ui;
    font-weight: lighter;
    color: #252323;
}

.card-overview .detail span,.card-overview-sub .detail span {

    font-size: 20px;
}

.dashboard-cont {
    font-family: 'Nunito', sans-serif;
}
.card-overview2 .detail{
    
        margin: 0px 20px;
        color: #666;
        font-size: 25px;
        font-family: 'Font Awesome 5 Free';
        margin-top: -12%;
    
}

.dashboard-cont .row {
    margin: 20px 0px;
    display: grid;
    margin: 20px 0px;
    display: grid;
    grid-template-columns: 30% 30% 30%;
    gap: 40px 5%;
}

.filtr .css-13cymwt-control{
    border: 2px solid #ededed !important;
}

.dashboard-cont .row2-parent {
    /* margin: 20px 0px; */
    margin: -16px 0px;
    display: grid;
    gap: 1%;
    /* grid-template-columns: 62% 37%; */
    grid-template-columns: 37% 62%
 
}

.dashboard-cont .row2 {
    margin: 20px 0px;
    display: grid;
    gap: 1%;
    grid-template-columns: 55% 45%;
    /* max-width: calc(100vw - 320px); */




}

.dashboard-cont .row2l {
    grid-template-columns: 100%;
    display: flex;
    flex-direction: column;
    flex-flow: column;
    height: 100%;
}

.chart-box {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    background-color: #fff;
    width: 100%;
    height: fit-content;
    min-height: 420px;
    border-radius: 7px;
    max-height: 500px;
    min-height: 420px !important;
    border-radius: 7px;
    max-height: 428px !important;

}

.chart-box2 {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    background-color: #fff;
    width: 100%;
    height: -moz-fit-content;
    height: fit-content;
    min-height: 420px;
    border-radius: 7px;
    min-height: 589px;
    height: 100%;
    padding-top: 10px;
    margin-top: 30px;

}

.row2-parent .heading-tbl {
    margin: 13px 24px;
    color: #666;
    font-family: 'Font Awesome 5 Free';
}

.chart-box-main-row {
    display: grid;
    align-items: center;
    height: 100%;
    grid-template-rows: 49% 49%;
    gap: 2%;
}

.upper-graphs {
    max-height: 500px;
}

.resp-box {
    min-height: fit-content;
    width: 95%;
    height: 320px;
    min-height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.card-overview {
    display: flex;
    justify-content: space-between;
    min-height: 161px;
    /* width: 360px; */
    border-radius: 4px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    background-color: #fff;
    position: relative;
    flex-direction: column;

}

.card-overview-inner {
    display: flex;
    justify-content: space-between;
    min-height: 127px;
    /* width: 360px; */
    border-radius: 4px;
    background-color: #fff;
    /* position: relative; */

}

.card-overview .bottom-info{
    
    /* display: flex;
    gap: 1rem;
    margin: 19px 18px;
    margin-top: 0; */


    display: flex;
    gap: 1rem;
    padding: 9px 18px;
    margin-top: 0;
    /* justify-content: flex-end; */
    /* margin-right: 8px; */
    background-color: #f1f1f1;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
    /* justify-content: flex-end; */
    /* margin-right: 8px; */
}
.card-overview .bottom-info .info-col{
    display: flex;
    align-items: center;
    
}

.card-overview .bottom-info .info-col i{
   margin-right: 4px;
   
   padding: 7px;
   border-radius: 4px;
   /* box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px; */
   color: #444;
   /* color: #fff; */
}
/* 
.card-overview .bottom-info .info-col i.client-icn{
   background-color: #347038;
   color: #f1f1f1;
}
.card-overview .bottom-info .info-col i.partner-icn{
    background-color: #20637d;
    color: #f1f1f1;
}

.card-overview .bottom-info .info-col i.send-icn{
    background-color: #3585ba;
    color: #f1f1f1;
    padding-left: 6px;

 }
 .card-overview .bottom-info .info-col i.receive-icn{
     background-color: #e7431f;
     color: #f1f1f1;
     padding: 4px 7px;
    
 } */


.card-overview .bottom-info .info-col .info-heading{
   font-size: 13px;
   font-weight: 600;
   color: #666;
}

.card-overview .bottom-info .info-col .info-value{
    font-size: 13px;
    margin-left: 6px;
    color: #666;
}


.card-overview-sub {
    display: flex;
    justify-content: space-between;
    min-height: 161px;
    /* width: 360px; */
    border-radius: 4px;
    /* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; */
    background-color: #fff;
    position: relative;
}
.card-overview2 {
    display: flex;
    justify-content: space-between;
    min-height: 161px;
    /* width: 360px; */
    border-radius: 4px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    background-color: #fff;
    position: relative;
    flex-direction: column;
}

.card-overview .col1,.card-overview-sub .col1 {
    width: 80%;

}
.dashboard-top-row2{
    display: grid;
    grid-template-columns: 65% 30%;
    gap: 40px 5%;
    margin-top: 40px;
    margin-bottom: 33px;
}
.dashboard-top-row2 .card-overview{
    margin-bottom: 43px;
}
.card-overview .col2,.card-overview-sub .col2 {

    width: 20%;
    display: flex;
    justify-content: center;
    align-items: start;

}

.center-graph{
    margin-top: 40px;
}


.card-overview .col2 i,.card-overview-sub .col2 i {

    color: hsl(246deg 27.23% 96.75%);
    font-size: 22px;
    padding: 20px 24px;
    /* background: #ff4100cf; */
    margin-top: -10px;
    margin-left: -27px;
    box-shadow: 0px 1px 4px 1px #0000002e;
    border-radius: 7px;
}

.bg1 {
    background: #ff4100cf;

}

.bg2 {
    background: #ddf009cf;

}

.bg3 {
    background: #191f64;
}

.bg4 {
    background: #333;
}

.tbl-action-btn-dwn:hover {
    color: #191f64;
}

.forgot-password-form-container h1 {
    font-size: 1.8rem;
    font-weight: normal;
    /* margin-top: -80px; */
    /* margin-bottom: 80px; */
    font-family: fantasy;
    background: var(--theme1);
    color: #fffffff0;
    text-align: center;
    padding: 30px;
    width: 100%;


}

.forgot-password-form-container .form {
    width: 80%;
    display: flex;
    justify-content: center;
    align-items: stretch;
    max-width: 700px;
    margin: auto;
    max-height: 70vh;
    height: 70vh;
    background-color: #fff;
    flex-direction: column;
    margin-top: -20px;
    border-radius: 19%;
    min-height: 483px;
}

.forgot-password-form-container .form label {
    width: 50%;
    margin-bottom: 40px;
}

.forgot-password-form-container .form input {
    /* margin-bottom: -20%;
    margin-top: 6%; */
}

.forgot-password-form-container .login-navigation-link {

    margin: 20px 0px;
    text-align: center;
    width: 100%;
}

.desktop-overview{
    display: flex;
    width:47%;
    margin-top: 20px;
}
.mobile-overview{
    display: none;
}


@media screen and (max-width:800px) {

    .cols-3,
    .cols-33 {
        grid-template-columns: 48% 48% !important;
        gap: 2rem !important;
    }
}

@media screen and (max-width:500px) {
    .forgot-password-form-container {
        width: 96%;
    }

    .dashboard-page-main .dashboard-cont{
        /* padding-top: 0; */
        padding: 3px;
    }

    .cols-3,
    .cols-33 {
        grid-template-columns: 100% !important;
        gap: 2rem !important;
    }
}

.mobile-tbl{
    display: none;
}



@media screen and (max-width:1260px) {
    .dashboard-cont .row2-parent {
        margin: 20px 0px;
        display: grid;
        gap: 1%;
        grid-template-columns: 100%;
    }
    .dashboard-filter-sect .filtr{
    
    width: auto;
    /* margin: 20px 0px; */
    margin: 0px 20px;
    }

 
    .dashboard-filter-sect{
        height:min-content
    }
    .dashboard-top-row2{
        grid-template-columns: 100%;
    }
    .bottom-overview{
        display: grid !important;
        grid-template-columns: 49% 49% !important;
        gap: 20px 20px;
    }
    .desktop-overview{
        display: none;
    }
    .mobile-overview{
        display: flex;
    }


}

@media screen and (max-width:1120px){
    .card-overview .col2 i, .card-overview-sub .col2 i {
       
        scale: 0.87 !important;
    }
    .card-overview h3, .card-overview-sub h3 {
        /* font-family: system-ui; */
    

        font-weight: 400;
        padding: 20px;
        font-size: 16.8px;
        color: rgb(167 152 152);
        padding-bottom: 14px;
    
    }
    .card-overview .detail, .card-overview-sub .detail {
     
        font-size: 21px;
    }
}

@media screen and (max-width:930px) {
    .dashboard-cont .row {
        margin: 20px 0px;
        display: grid;
        margin: 20px 0px;
        display: grid;
        grid-template-columns: 50% 50%;
        gap: 12px;
    }
    .bottom-overview{
        display: flex !important;
    }
    .dashboard-filter-sect .filtr{
        width:100%;
        margin:20px 0px;

    }
    .dashboard-cont .row2,
    .dashboard-cont .row3 {
        grid-template-columns: 100%;
        gap: 4%
    }

    .chart-box {
        margin-top: 20px;
    }
    .card-overview .col2 i, .card-overview-sub .col2 i {
       
        scale: 0.99 !important;
    }
    .card-overview h3, .card-overview-sub h3 {
        /* font-family: system-ui; */
        font-weight: 400;
        padding: 20px;
        font-size: 20px;
        color: rgb(167 152 152);
    }
        .card-overview .detail, .card-overview-sub .detail {
     
        font-size: 28px;
    }
}


@media screen and (max-width:725px){
    .card-overview .col2 i, .card-overview-sub .col2 i {
       
        scale: 0.87 !important;
    }
    .card-overview h3, .card-overview-sub h3 {
        /* font-family: system-ui; */
    

        font-weight: 400;
        padding: 20px;
        font-size: 16.8px;
        color: rgb(167 152 152);
        padding-bottom: 14px;
    
    }

        .card-overview .detail, .card-overview-sub .detail {
     
        font-size: 21px;
    }
}

.disabled-btn{
    background: #898989 !important;
}


@media screen and (max-width:767px) {
    .login-img-col {
        display: none;
    }
    .desktop-tbl{
        display: none;
    }
    .mobile-tbl{
        display:block;
    }
}

@media screen and (max-width:550px) {

    .dashboard-cont .row {
        margin: 20px 0px;
        display: grid;
        margin: 20px 0px;
        display: grid;
        grid-template-columns: 100%;
        gap: 12px;
    }

    .employee-page-header h2 {
        font-weight: 400;
        font-family: emoji;
        font-size: 19px;
    }

    .card-overview{
        margin-bottom: 30px;
    }

}