.sidebar-main {
    background-color: var(--sidebar);
    width: 300px !important;
    min-width: 300px;
    max-width: 300px;
    min-height: 100vh;
    border-radius: 3px;
    color: #ffffff;
    font-family: var(--roboto-font);
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    padding: 0;
    position: relative;
    top: 0;
    left: 0;
    margin-right: 1px;
}

.sidebar-col-inner {
    min-height: 100vh;
    /* width: 99%; */
    width: 100%;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    position: sticky;
    top: 0;
    left: 0
}

.sidebar-main .navigation-container {
    display: flex;
    flex-direction: column;
    margin-top: 41px;


}

.navigation-container a {
    color: #484848;
    text-decoration: none;
}

.main-routing-page {

    width: 95%;
    margin: 20px auto;
    min-height: 80vh;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-radius: 5px;
}

.side-nav-parent {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: calc(100% + 15px);
    /* padding: 20px 10%; */
    font-size: 16px;
    cursor: pointer;
    margin: 0px 10px;
    background-color: transparent;
    /* border-bottom: 1px solid #f1f1f1; */
    /* box-shadow: rgba(60, 64, 67, 0.3) 0px 0px 0px 0px, rgba(60, 64, 67, 0.15) 0px 2px; */
    /* margin-bottom: 10px; */
    margin-left: -15px;
}

.side-nav-parent:hover {
    background-color: #f2f2f2;
}

.sub-navigation:hover {
    background-color: #d2cbcb;
}

.side-nav {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;

    padding: 20px 10%;
    font-size: 16px;
    cursor: pointer;
    margin: 0px 10px;
    width: 95%;
    /* background-color: transparent;
    border-bottom: 1px solid #2b3443; */

}


.sidebar-heading img {
    margin: 0px 36px;
    scale: 1.2;
}

.side-nav:hover {
    background-color: rgba(242, 242, 242, 0.188);
}

.page-col {
    max-width: 100vw;
    flex: 1;
    background-color: #f2f2f2;
    margin: 0;
    padding: 0;

}

.navbar {
    background-color: transparent;
    /* margin: 30px; */
    min-height: 74px;
    /* border-radius: 10px; */
    /* box-shadow: rgba(0, 0, 0, 0.13) 0px 0px 11px; */
    width: calc(100% - 0px);
    /* margin: 0px -3px; */
    background: var(--theme1);
    /* border-bottom: 6px solid #2e3e50; */
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    position: sticky;
    top: 0;
    left: 0;
    z-index: 10;
    margin-left: -1px;
}

.z-1{
    /* z-index: 10000 !important; */
}

.admin-page-parent {
    padding-bottom: 7vh;
    min-height: 90vh;
    background-color: #fff;

}



.employee-tbl {}

.sidebar-main .preview {
    display: none;
    margin-right: 10px;
}

@media screen and (max-width:1300px) {
    .sidebar-main {
        margin-left: -220px;
        transition: 0.6s ease;
    }

    .sidebar-main:hover {
        margin-left: 0% /*!important*/;
        transition: 0.6s ease;

    }

    .sidebar-main .preview {
        display: block;
    }

    .sidebar-main:hover .preview {
        display: none;
    }
}

.s-left-300{
    z-index:1000;
}

@media screen and (max-width:500px) {
    .menu-mobile{
        display: block;
    }

    .z-1{
        z-index: 0;
    }

    .s-left-300{
        z-index: 0;
    }

    .sidebar-main {
        background-color: var(--sidebar);
        width: 300px !important;
        min-width: 300px;
        max-width: 300px;
        min-height: 100vh;
        border-radius: 3px;
        color: #ffffff;
        font-family: var(--roboto-font);
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        padding: 0;
        position: fixed;
        top: 0;
        /* left: -300px; */
        margin-right: 1px;
    }
    
}

.s-left-0{
  z-index:10;
}



.dashboard-layout-main{
   flex-wrap: nowrap;
   margin: 0 ;
}