.App {

}
*{
  margin: 0;
  padding:0;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.reload-sect{
  display: flex;
  gap:0.73rem;
  cursor: pointer;
  align-items: center;
  padding:0.46rem;

}

.reload-sect:hover{
  background-color: #f1f1f1;
}

.hidden{
  display: none !important;
}

.custom-upload-btn{
  appearance: none;
  background-color: #FAFBFC;
  border: 1px solid rgba(27, 31, 35, 0.15);
  border-radius: 6px;
  box-shadow: rgba(27, 31, 35, 0.04) 0 1px 0, rgba(255, 255, 255, 0.25) 0 1px 0 inset;
  box-sizing: border-box;
  color: #24292E;
  cursor: pointer;
  display: block;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  list-style: none;
  padding: 6px 16px;
  position: relative;
  transition: background-color 0.2s cubic-bezier(0.3, 0, 0.5, 1);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: middle;
  white-space: nowrap;
  word-wrap: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
}

.custom-upload-btn:hover{
  background-color: #8a8b8b;
  color:#FAFBFC ;

}


.custom-upload-btn:after{
  content: "\f093";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  --spacing-inline: 10px;
}



@media screen and (max-width:500px) {
  .mobile-hide{
    display: none !important;
  }
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.mobile-view{
  display: none !important;
}
@media screen and (max-width:500px) {
  .desktop-view{
    display: none !important;
  }
  .mobile-view{
    display: initial !important;
  }
  .mobile-view .language-dropdown i,   .mobile-view .language-dropdown span{

    color: #333;
  }

}


:root {
  --roboto: 'Roboto', sans-serif;
  --sidebar:#fff;
  --poppins: "Poppins", sans-serif;
  --btn-col:rgb(29, 29, 66);
  --theme1:linear-gradient(to right, #ff7200, #ff6300, #ff5200, #ff3e00, #ff1f01);
  --opensans:"Public Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}

.custom-button{
  background-color: var(--btn-col);
  padding:7px 42px;
  color:#f1f1f1;

}




/* .font-roboto{
  font-family: 'Quicksand', sans-serif;
  font-family: 'Roboto', sans-serif;

} */
