.employee-tbl-header {
    padding: 13px;
    /* background-color: rgb(19, 26, 70); */
    font-weight: 400;
    color: #766868;
    /* border-bottom: 2px solid #ddd;
    border-top: 2px solid #ddd; */
    border: 1px solid #ededed;
    font-family: var(----roboto)
}

.sidebar-heading {
    padding: 22px;
    font-weight: normal;
    font-size: 22px;
    box-shadow: rgba(0, 0, 0, 0.13) 0px 0px 11px;
    width: calc(100% + 15px);
    margin-left: -15px;
    background: rgb(255, 255, 255);
    /* border-bottom: 6px solid rgb(46, 62, 80); */
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, .075);
}

.form-grp-lbl {

    font-size: 23px;
    padding: 0 13px;
    padding-bottom: 13px;
    /* border-bottom: 2px solid #ededed; */
    color: #7c7c7c;
    margin-bottom: 30px;
    margin-top: 22px;
    font-family: 'Font Awesome 5 Free';

}

.alert-modal .modal-title {
    width: 100%;
    color: #fff;
}

.alert-modal .modal-body {
    font-family: 'Roboto';
}

.alert-modal .modal-header {
    background-color: #ff4608;

}

.alert-modal .modal-title i {
    float: right;
}

.back-btn {
    display: flex;
    align-items: center;
    margin-right: 30px;
    color: #85b5f1;
    cursor: pointer;

    display: flex;
    align-items: center;
    margin-right: 30px;
    color: #eaeaea;
    cursor: pointer;
    padding: 3px 5px;
    background: linear-gradient(to right, #F4511E, rgb(255 189 147));
    background: linear-gradient(to right, #e95628, rgb(250 96 50), #e44411) !important;
    border-radius: 9px;
    min-width: 101px;
    height: 40px;
    text-align: center;
    justify-content: center;
    border: 2px solid #f68942;



}

.back-btn i {
    margin-left: 17px;
    margin-right: 4px !important;
    background: transparent !important;
    scale: -1;
    border-left: 2px solid #f9b030;
    padding-left: 7px;
}

.back-btn i {
    margin-left: -4px;
    margin-right: 6px;
}


.back-btn span {
    font-weight: 600;
    font-size: 14px;
    font-family: 'Nunito';
}

.tabs-parent-sect {
    background-color:
        /* #191f64*/
        #273f63 !important;
    box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px;
    color: #f2f2f2 !important;
    padding-bottom: 0 !important;
    padding: 0px 0px !important;
    margin-left: -1px;
}

.tab-navigation {
    padding-bottom: 20px;
    cursor: pointer;
    color: #e4dede !important;
    font-size: 13px !important;
    font-weight: bold;
    font-family: 'Nunito', sans-serif;
    ;
    position: relative;
    /* margin: 0 21px; */
    padding-left: 11px !important;
    padding-right: 11px !important;
    padding-top: 20px;
}

.tab-navigation:hover,
.tab-activ {
    background-color: #030949 !important;
}

.tab-activ::after {
    /* border-bottom: 5px solid rgba(236, 100, 10, 0.922); */
    /* padding: 20px 10px; */
    padding-top: 0px;
    width: 100%;
    content: "";
    height: 4px;
    background: rgba(236, 100, 10, 0.922);
    position: absolute;
    bottom: 0px;
    left: 0px;
    animation-name: border-animate;
    animation-duration: 0.3s;


}

@keyframes border-animate {
    from {
        width: 0%;
    }

    to {
        width: 100%;
    }
}

.role-lbl {

    color: rgb(255, 255, 255);
    font-size: 12px;
    font-weight: 200;
    margin-top: -12px;
    display: block;

}


.employee-td {
    background-color: #ffffff;
    background-color: #ffffff;
    /* box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.1); */
    margin-bottom: 20px;
    padding: 9px;
    /* border-radius: 4px; */
    font-size: 14px;
    /* font-family: var(--poppins); */
    color: black;
    font-weight: 320;
    /* border-bottom: 1px solid #ddd; */
    border: 1px solid #ededed;
    background-color: #f9f9f9;

}

.search-comp-sect {
    display: flex;
    margin: 20px 0px;
}

.search-comp-sect input {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    padding: 19.2px;

}

.menu-toggle-icn {
    float: right;
    margin-right: 10px;
    color: #333;
}

.tbl-main-parent {
    width: 90%;
    margin: auto;
}

.create-invoice-form-bottom {
    display: flex;
    justify-content: space-between;
    margin: 20px;
}

.w-100 {
    width: 100%
}

.global-filter-search-btn {
    padding: 7px;
    background-color: #192869;
    color: #fff;
}

.tbl1 {
    /* box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px; */
    min-width: 890px;
    overflow-x: auto;
    width: 100%;
    margin: 0px auto;
    border-collapse: separate;
    border-spacing: 0px 2px;

}

.tbl1 th,
.tbl1 td {
    font-family: var(--roboto);
    color: #666
}

.profile-dropdown,
.language-dropdown {
    background-color: transparent !important;
    color: #333 !important;
    border: none !important;
    box-shadow: none !important;
    font-family: var(--opensans);
    font-weight: normal;
}

.dropdown {
    display: inline-block;
}

.language-dropdown {
    align-items: center;
}

.language-dropdown i {
    color: #f1f1f1;
    font-size: 29px;
}

.language-dropdown span {
    margin-left: 13px;
    font-size: 16px;
    color: #f1f1f1;
    margin-top: -10px;
    position: relative;
    top: -3px;
    left: -5px;
    font-family: math;
}

.profile-dropdown:hover,
.language-dropdown:hover {
    background-color: #f1f1f1;
    color: #333;
    border: none !important;
}

.profile-dropdown::after {
    display: none;
}

.nav-profile-sect {
    padding: 7px;
    background-color: rgb(255, 255, 255);
    border-radius: 50%;
    border: 1px solid #c5c5c5;
    border-radius: 50%;
}


.navbar {
    display: flex;
    flex-direction: space-between;
}

.tbl-action-btn {
    font-size: 15px;
    color: #f1f1f1;
    padding: 7px;
    margin: 4px;
    background: var(--theme1) !important;
    border: none;
}

.tbl-action-btn i {
    margin: 0px 10px;
    cursor: pointer;
    color: #fff;
    font-size: 10px;
}



.table-paginate {

    display: flex;
    justify-content: space-between;
    width: 95%;
    margin: auto;
    align-items: center;


}

.page-size-dropdown {
    background: none;
    color: #333;
    border: 1px solid #c5c5c5;
    border-radius: 6px;
    font-size: 13px;
    display: flex;
    align-items: center;
    margin: 22px 0px;
}


.paginate-btn {
    border: none;
    padding: 10px;
    border-radius: 10px;
    width: 44px;
    height: 44px;
    margin: 10px;
    background: var(--theme1) !important;
    font-size: 20px;
    color: #fff;
}

.paginate-btn:hover {

    background: linear-gradient(to right, #ff7300b7, #ff6200d6, #ff5100cb, #ff4000c7, #ff1f01c3);


}

.menu-mobile {
    display: none;
}

.sub-navigation:hover {
    background-color: #c5c5c5;
}

.paginate-btn svg {
    margin-top: -10px;

}

.tbl-title-header {
    border: 1px solid #e8e8e8;
    background-color: #f9f9f9;
    margin-top: 0px;
}

.tbl-title-header h2 {
    padding: 10px 12px;
    display: block;
    font-size: 16px;
}

.top-lbl-main {
    background-color: #f9f9f9;
    border-bottom: 1px solid #dcdcdc;
    display: flex;
    padding: 20px 10px;

}

.route-data {
    display: flex;
    justify-content: start;
    align-items: center;
    color: #767676;

}

.route-data p {

    margin: 0px 11px
}

.nav-left-menu {
    display: flex;
    align-items: center;
    gap: 10px;

}

.inv-genform {
    padding: 20px;
    background-color: #fff;
    display: grid;
    /* box-shadow: 0px 1px 1px 1px rgb(0 0 0 / 15%); */
    /* box-shadow: 0px 3px 10px -2px rgba(0, 0, 0, 0.2); */
    display: grid;
    grid-template-columns: 99%;
    gap: 5%;
    justify-content: center;
    align-items: flex-start;
}

.inv-genform-heading {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 6rem;
}

.inv-genform-heading .add-logo-btn {
    border: 1px solid #e8e6e6;
    padding: 2rem;
    color: #b1a8a8;
    background-color: #e4e5e5;
    margin-bottom: 0;
    text-align: center;


}

.inv-genform-heading .heading-lbl {
    font-size: 34px;
    color: #666262;
}

.inv-genform .mid-row1 {
    display: grid;
    grid-template-columns: 66% 34%;
    width: 100%;
}

.inv-genform .mid-row1 .company-details,
.inv-genform .mid-row1 .col1,
.inv-genform .mid-row1 .col2,
.invoice-products-list-tbl-main {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.inv-genform .mid-row2 .col1 .notes-terms-details {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.inv-genform .mid-row2 .col1 .notes-terms-details .cp-name {
    padding: 10px;
}

.inv-genform .mid-row2 {
    margin-top: 3rem;
    width: 100%;
    display: grid;
    grid-template-columns: 60% 40%;
}

.inv-genform .mid-row2 .col2 {
    display: grid;
    grid-template-columns: 100%;
    width: 100%;
}

.inv-genform .mid-row2 .col2 span {
    text-align: right;
    color: #656262;
    text-align: right;
    color: #656262;
    font-weight: 300;
    font-size: 13px;

}

.billing-details .cp-name {
    padding: 10px
}

.billing-client-label {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.billing-client-label .partner-details {
    display: flex;
    flex-direction: column;
    gap: 12px;
}



.billing-client-label .partner-details .partner-name {
    font-size: 16px;
    color: #555;
    font-weight: 600;

}

.billing-client-label .partner-details .partner-email {
    color: #192869;
}

.billing-client-label .partner-details .partner-number {
    color: #3b3b3b;
}

.billing-details .css-1nmdiq5-menu {
    min-width: 600px;
    max-width: 700px;
    width: 70vw;
}

.inv-genform .mid-row2 .col2 button {
    border: 1px solid #c1c1c1;
    background-color: transparent;
    color: #656262;
    padding: 6px 10px;
    border-radius: 10px;
    margin-left: 13px;
}

.inv-genform .mid-row2 .col2 .billing-overview-row {
    display: grid;
    gap: 2%;
    grid-template-columns: 44% 44% 4%;
    width: 100%;
    margin-bottom: 14px;
    align-items: center;

}


.invoice-products-list-tbl-main {
    margin-top: 1.8rem;
}

.invoice-products-list-tbl-main .invoice-products-list-tbl-header {
    background-color: #030949;
    padding: 10px 22px;
    border-radius: 7px;
    display: grid;
    grid-template-columns: 55% 15% 15% 10% 10px;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 10px;
    padding-right: 0;
}

.invoice-products-list-tbl-main .invoice-products-list-tbl-rowdata {
    background-color: #030949;
    padding: 10px 0px;
    border-radius: 7px;
    display: grid;
    grid-template-columns: 55% 30% 10% 10px;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 1px 10px;
    /* margin */
}

.invoice-products-list-tbl-main .invoice-products-list-tbl-rowdata .amt-wrap {
    display: grid;
    width: 100%;
    grid-template-columns: 50% 50%;
}

.amt-wrap .qty-icn {
    display: none;
}

.add-inv-product-row-btn {
    display: inline-block;
    width: max-content;
    display: inline-block;
    width: max-content;
    background: transparent !important;
    border: 2px solid #ff2404;
    color: #ff2404;

}

.add-inv-product-row-btn:hover {
    background: #ff2404 !important;
    border: 2px solid #ff2404;
    color: #fff;
}

.invoice-products-list-tbl-rowdata {
    background-color: transparent !important;
}

.invoice-products-list-tbl-rowdata input {
    border: 1px solid #c1c1c1;
    border-radius: 5px;
    padding: 1.4rem 10px;
    margin-left: 0;
    margin-right: 10px;
}

.invoice-products-list-tbl-rowdata input:first-child {
    margin-left: 0;
}

.invoice-products-list-tbl-rowdata input::placeholder {
    color: #666262;
    padding-left: 1.4rem;
}

.invoice-products-list-tbl-main .invoice-products-list-tbl-header .form-label {
    color: #fff;
    margin-bottom: 0;
    padding: 0rem 10px;
    margin-left: 0;
    margin-right: 10px;
}
.sidebar-nav-title-main{
    color: #484848;
}

.amt-label {
    text-align: right;
    padding-right: 20px;
}

.invoice-products-list-tbl-main .invoice-products-list-tbl-header .form-label:first-child {
    margin-left: 0;

}


/* .invoice-products-list-tbl-main{
    display:flex;
    flex-direction: column;
} */

.inv-genform .mid-row1 .col2 {
    gap: 15px;
}

.inv-genform .mid-row1 .company-details {
    padding: 16px 0;
}

.inv-genform .company-details b {
    color: #3f4041;
    font-weight: 600;

}

.inv-genform .company-details .form-label {
    margin-bottom: 2px;
}

.inv-genform .mid-row1 .billing-details {
    display: flex;
    flex-direction: row;
    gap: 12px;

}

.table-details-row {

    display: grid;
    grid-template-columns: 48% 48%;
    background-color: #e1e4e7;
    padding: 13px;
    margin-bottom: 0px;
    border: 1px solid #c5c5c5
}

.table-details-row:nth-child(even) {
    background-color: #aeb1b552;

}

.table-details-modal .modal-title {
    width: 100%;
}

.table-details-modal .modal-body {
    padding: 0;
}

.table-details-row div {
    /* border:1px solid #ededed */
}

.inv-genform .mid-row2 .col2 .billing-overview-row .tax-btn,
.inv-genform .mid-row2 .col2 .billing-overview-row .amt-btn {
    display: flex;
    justify-content: space-between;
    padding: 0;
    /* padding: 10px 20px; */

    border: 1px solid #dcdcdc;
    
    max-width: 260px;

    /* align-items: center; */
}

.inv-genform .mid-row2 .col2 .billing-overview-row .tax-btn .switchbtn {
    border-left: 1px solid #dcdcdc;
    ;
    background: #f1f1f1;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}


.inv-genform .mid-row2 .col2 .billing-overview-row .tax-btn .switchbtn,
.inv-genform .mid-row2 .col2 .billing-overview-row .tax-btn .preview,
.inv-genform .mid-row2 .col2 .billing-overview-row .tax-btn .value {
    padding: 10px 20px;
    /* border-left: 1px solid #555; */
}

.inv-genform .mid-row2 .col2 .billing-overview-row .tax-btn .value {
    width: 60%;
    padding-left: 21px;
    text-align: left;
}


.inv-genform .mid-row2 .col2 .billing-overview-row .amt-btn .switchbtn,
.inv-genform .mid-row2 .col2 .billing-overview-row .amt-btn .preview,
.inv-genform .mid-row2 .col2 .billing-overview-row .amt-btn .value {
    padding: 10px 20px;
    /* border-left: 1px solid #555; */
}

.add-tax-btn-sect {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 1.1rem;
    margin: 20px auto;

}

.add-tax-btn-sect span {
    color: #000a7abf !important;
    font-weight: 600 !important;
    font-size: 14px !important;
    cursor: pointer;
}

.invoice-products-list-tbl-rowdata .del-btn {
    float: right;
}

.invoice-products-list-tbl-rowdata .del-btn .msg {
    display: none;
}
#sidebar-menu-btn{
    margin-right: 0;
}

.inv-date-option-wrap,.inv-date-option-wrap .inv-option{
    display: flex;
    /* justify-content: space-between; */
    align-items: center;
    gap: 9px;
}

.inv-date-option-wrap .inv-option{
   gap: 2px;
   /* flex-direction: row-reverse; */
   padding-left: 10px;
}
.inv-date-option-wrap .inv-option input[type="radio"]{
    width: max-content;
    margin: 8px;
}
.inv-date-option-wrap .inv-option label{
    color: #313131;
}

@media screen and (max-width:1164px) {
    .inv-genform .mid-row1 {
        display: grid;
        grid-template-columns: 100%;
        width: 100%;
        gap: 6px;
    }

    .inv-genform .mid-row1 .billing-details {
        display: flex;
        flex-direction: row;
        gap: 12px;
        justify-content: flex-start;
        margin-bottom: 21px;
    }

    .invoice-generate-form-main,
    .inv-genform {
        padding-left: 0;
    }

    .inv-genform .mid-row2 {
        margin-top: 3rem;
        width: 100%;
        display: grid;
        grid-template-columns: 100%;
    }

    .inv-genform .mid-row2 .col2 {
        display: grid;
        grid-template-columns: 100%;
        width: 100%;
        margin-top: 20px;
    }
}

@media screen and (max-width:700px) {

    .inv-genform-heading,
    .inv-genform .mid-row1 .billing-details {

        /*  */
        flex-direction: column;
        gap: 11px;
    }

    .invoice-products-list-tbl-main .invoice-products-list-tbl-rowdata {

        grid-template-columns: 100%;

        /* margin */
    }

    .invoice-products-list-tbl-main .invoice-products-list-tbl-rowdata input,
    .invoice-products-list-tbl-main .invoice-products-list-tbl-rowdata span {
        margin: 7px 0;
        ;
    }

    .invoice-products-list-tbl-main .invoice-products-list-tbl-rowdata .amt-wrap {
        display: grid;
        width: 100%;
        grid-template-columns: 46% 30px 46%;
        align-items: center;
    }

    .invoice-products-list-tbl-main .invoice-products-list-tbl-header {

        grid-template-columns: 1fr 1fr 1fr 1fr;
    }

    .amt-wrap .qty-icn {
        display: inline-block;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .inv-genform .mid-row2 .col2 .billing-overview-row {
        display: grid;
        gap: 2%;
        grid-template-columns: 27% 54% 4%;
    }


    .inv-genform .mid-row2 .col2 .billing-overview-row .tax-btn,
    .inv-genform .mid-row2 .col2 .billing-overview-row .amt-btn {
        display: flex;
        justify-content: space-between;
        padding: 0;
        /* padding: 10px 20px; */

        border: 1px solid #dcdcdc;
        ;
        /* align-items: center; */
    }

    .inv-genform .mid-row2 .col2 .billing-overview-row .tax-btn .switchbtn {
        border-left: 1px solid #dcdcdc;
        ;
        background: #f1f1f1;
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
    }


    .inv-genform .mid-row2 .col2 .billing-overview-row .tax-btn .switchbtn,
    .inv-genform .mid-row2 .col2 .billing-overview-row .tax-btn .preview,
    .inv-genform .mid-row2 .col2 .billing-overview-row .tax-btn .value {
        padding: 10px 20px;
        /* border-left: 1px solid #555; */
    }


    .inv-genform .mid-row2 .col2 .billing-overview-row .amt-btn .switchbtn,
    .inv-genform .mid-row2 .col2 .billing-overview-row .amt-btn .preview,
    .inv-genform .mid-row2 .col2 .billing-overview-row .amt-btn .value {
        padding: 10px 20px;
        /* border-left: 1px solid #555; */
    }

    .invoice-products-list-tbl-rowdata .del-btn {
        float: right;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px;
        background-color: #F4511E;
        color: #fff;
        border-radius: 4px;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    }

    .invoice-products-list-tbl-rowdata .del-btn:hover {
        float: right;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px;
        background-color: #f4501ec2;
        color: #fff;
        border-radius: 4px;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    }

    .invoice-products-list-tbl-rowdata .del-btn .msg {
        display: block;
        margin-right: 13px;
    }
}

@media screen and (max-width:500px) {
    .employee-td {
        font-size: 12px;
    }

    .tbl1 {
        min-width: 100%;
    }

    .tbl-action-btn {
        font-size: 12px;
        color: #f1f1f1;
        padding: 6px;
        margin: 4px;
        background: var(--theme1) !important;
        border: none;
    }

    .menu-mobile {
        display: block;
    }

    .back-btn span {
        display: none !important;
    }

    .back-btn {
        width: fit-content;
        min-width: 48px;
        background: transparent !important;
        border: 0 !important;
    }

    .back-btn i {
        border-left: none;
        margin-right: 10px;
    }

    .sidebar-main {
        background-color: var(--sidebar);
        width: 300px !important;
        min-width: 300px;
        max-width: 300px;
        min-height: 100vh;
        border-radius: 3px;
        color: #ffffff;
        font-family: var(--roboto-font);
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        padding: 0;
        position: fixed;
        top: 0;
        left: -300px;
        margin-right: 1px;
    }

    .tab-navigation {
        font-size: 10px !important;
        padding-left: 7px;
        padding-right: 7px;


    }



    
    .inv-genform .mid-row2 .col2 .billing-overview-row {
            display: grid;
            gap: 2%;
            grid-template-columns: 20% 70% 4%;
    }

    .inv-genform .mid-row2 .col2 span {
        
        font-size: 11px;
    }
    

}

@media screen and (max-width:350px) {
    .tab-navigation {
        font-size: 7.7px !important;
        padding-left: 7px;
        padding-right: 7px;
    }
}

.loading-page-main {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: calc(100vh - 74px);

}

.loading-page-main h1 {
    font-size: 22px;
    margin: 30px 0px;
    font-weight: normal;
    color: #333333ad;
}
.loading-page-main .rertry-btn{

}

.form-drpdwn {
    width: 100%;
}

.form-drpdwn .css-13cymwt-control {
    border: none;

}

.form-drpdwn .css-qbdosj-Input,
.form-drpdwn .css-1fdsijx-ValueContainer {
    margin-top: 0px;
    margin-bottom: 0px;
    padding-top: 0px;
    padding-bottom: 0px;
}


.date-input {
    width: 90%;
}

.sub-link {
    font-size: 12.6px;
}

.sub-link:hover,
.side-nav:hover {
    font-weight: 600;
    color: #192869;
}



.comment-heading span {
    font-size: 13px;
    color: #2e86d6;
    margin-left: 14px;
}

.comment-heading {}

.comment-heading {
    margin: 40px 10px;
    font-size: 17px;
    color: #333;
    font-family: sans-serif;
}

.comment-body::before,
.comment-body::after {
    content: '"';
    margin: 0px 10px;
}

.comment-body {
    font-style: italic;
    color: gray;
}

.comment-popup-body-main {
    padding: 30px 0px;
    max-height: 400px;
    overflow: auto;
}

.comment-modal-header {
    margin: 0px 0px;
}

.comment-modal-header .modal-title {
    font-weight: 600 !important;
    width: 100%;
    display: flex;
    justify-content: space-between;

}

.comment-msg-area {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border: none;
}

.comment-modal-footer {
    display: flex;
    flex-direction: column;

}

.comment-modal-footer button {
    margin: 10px 0px;
    margin-right: 9px;
    width: 100%;
    font-weight: bold;
}

.comment-modal-header,
.comment-modal-footer {
    background-color: #f0f0f0;
}

.modal-footer>* {
    margin: 0;
}

.comment-row {
    padding: 14px 34px 21px;
    border-bottom: 1px solid #e0e0e0;
}

.comment-sub-row {
    display: flex;
    align-items: center;
}

.comment-sub-row .fa-comments {
    margin-right: 14px;
    font-size: 32px;
    color: gray;
}

.sub-side-nav {
    padding: 10px 10%;
}

.retry-btn {
    color: #1060d4;
    cursor: pointer;
}

.route-data {
    font-size: 12px;
}

/* .employee-tbl-header:nth-child(1) {
    border-radius: 5px 0px 0px 0px;
}

.employee-tbl-header:nth-last-child(1) {
    border-radius: 0px 5px 0px 0px;
} */