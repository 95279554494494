.partner-form .cols-3,.partner-form .cols-33{
  grid-template-columns: 48% 48% !important;
}



.create-partner-form-main .form-body.partner-form {
    padding: 20px;
    background-color: #fff;
    display: grid;
    /* box-shadow: 0px 1px 1px 1px rgb(0 0 0 / 15%); */
    /* box-shadow: 0px 3px 10px -2px rgba(0, 0, 0, 0.2); */
    display: grid;
    grid-template-columns: 46% 46%;
    gap: 5%;
    justify-content: center;
    align-items: flex-start;
}

/* .create-partner-form-main .form-body.consultant-form {
    padding: 20px;
    background-color: #fff;
    display: block;

    gap: 5%;
    justify-content: center;
    align-items: flex-start;
} */


.form-horizontal{
    display: flex;
    align-items: flex-start;
    flex-direction: column;
}
.partner-form .inp-rows{
    gap: 1.04rem !important;
}

@media screen and (max-width:1100px) {
    .partner-form .cols-3,.partner-form .cols-33,.partner-form .inp-rows{
        grid-template-columns: 99% !important;
      }
}

@media screen and (max-width:600px) {
    .create-partner-form-main .form-body.partner-form {
        padding: 20px;
        background-color: #fff;
        display: grid;
        /* box-shadow: 0px 1px 1px 1px rgb(0 0 0 / 15%); */
        /* box-shadow: 0px 3px 10px -2px rgba(0, 0, 0, 0.2); */
        display: grid;
        grid-template-columns: 99%;
        gap: 0%;
        justify-content: center;
        align-items: flex-start;
    }
}